"use client";

import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  type FC,
  type ReactNode,
} from "react";
import { useIntl } from "react-intl";
import { useAd } from "./AdContext";
import { buildBenjiAdConfig, type BenjiPlatform, type Device } from "./benji";
import {
  buildScreenWidthMediaQuery,
  type ScreenWidth,
  type Size,
} from "./client";

export type AdDisplayMode = "collapsed" | "hidden" | "placeholder" | "visible";
export type AdLoadingMode = "eager" | "lazy";

export interface AdWrapperProps {
  children: ReactNode;
  displayMode: AdDisplayMode;
  isPremium: boolean;
  locationNumber: number;
}

export interface BaseAdProps {
  className?: string;
  loading?: AdLoadingMode;
  location: string;
  locationNumber?: number;
  onResize?: ResizeEventHandler;
  pageRegion: string;
  placement?: string;
  platform?: BenjiPlatform;
  /**
   * Determines if the ad container can resize itself:
   * - shrinking down to the served ad's size, if smaller
   * - collapsing if an ad fails to serve
   *
   * WARNING: when enabled, this can lead to layout shifts if not used carefully
   */
  resizable: boolean;
  site: string;
  /**
   * used only when we want to render ads with Benji adstacking feature
   */
  stackGroup?: string;
  id?: string;
}

export interface ScreenSpecificAdProps {
  customSizeConfig?: Record<string, boolean>;
  device: Device;
  ntsFallBack?: {
    position: string;
  };
  placeholder: Size;
  screenWidth: ScreenWidth;
  sizes: Size[];
  wrapper?: FC<AdWrapperProps>;
}

export interface AdProps extends BaseAdProps, ScreenSpecificAdProps {
  kvs?: Record<string, string>;
}

interface ResizeEventHandler {
  (size: Size, isPremium: boolean): void;
}

const DefaultAdWrapper: FC<AdWrapperProps> = ({ children }) => <>{children}</>;

/**
 * Uses React's `useId` to generate a stable identifier for an element,
 * removing special characters that Benji doesn't support and that CSS doesn't
 * play nicely with, namely `:`
 */
const useSanitizedId = () => {
  const reactId = useId();

  return reactId.replace(/:/g, "");
};

export const Ad: FC<AdProps> = ({
  className,
  customSizeConfig,
  device,
  kvs,
  loading = "eager",
  location,
  locationNumber = 1,
  ntsFallBack,
  onResize,
  pageRegion,
  placement = "ros",
  platform,
  resizable,
  screenWidth,
  site,
  sizes,
  stackGroup,
  id,
  wrapper: Wrapper = DefaultAdWrapper,
}) => {
  /**
   * For Benji adstacking, div IDs should match those requested from Benji.
   * Such as sda-LREC3-iframe, sda-LREC4-iframe & sda-MON2-iframe.
   * */
  const fetchedID = useSanitizedId();
  const adId = id ? id : `${fetchedID}`;
  const containerId = useSanitizedId();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);
  const [enabled, setEnabled] = useState(loading === "eager");
  const [renderedAd, setRenderedAd] = useState<Size | null>(null);

  const config = useMemo(
    () =>
      buildBenjiAdConfig({
        customSizeConfig,
        device,
        id: adId,
        kvs,
        location,
        locationNumber,
        ntsFallBack,
        placement,
        platform,
        region: pageRegion,
        site,
        sizes,
        stackGroup,
      }),
    [
      adId,
      customSizeConfig,
      device,
      kvs,
      location,
      locationNumber,
      ntsFallBack,
      pageRegion,
      placement,
      platform,
      site,
      sizes,
      stackGroup,
    ],
  );

  const ad = useAd({
    config,
    enabled: visible && enabled,
  });
  let displayMode: AdDisplayMode;
  if (ad.status === "rendered") {
    displayMode = "visible";
  } else if (ad.status === "error") {
    displayMode = resizable ? "collapsed" : "hidden";
  } else {
    displayMode = "placeholder";
  }

  const containerSelector = `#${containerId}`;
  const responsiveStyles = [
    `${containerSelector} { display: none; }`,
    displayMode !== "collapsed" &&
      `@media ${buildScreenWidthMediaQuery(screenWidth)} {
        ${containerSelector} { display: flex; }
        iframe { max-width: 100vw; }
      }`,
  ]
    .filter(Boolean)
    .join("\n");

  const getContainerStyle = () => {
    if (
      (resizable && ["visible", "placeholder"].includes(displayMode)) ||
      renderedAd?.isPremium ||
      (ad.status === "error" && ntsFallBack)
    ) {
      return {};
    }
    return {
      height: Math.max(...sizes.map((size) => size.height)),
      width: Math.max(...sizes.map((size) => size.width)),
    };
  };

  useEffect(() => {
    const resizeCallback = () => {
      const width = document.documentElement.clientWidth;
      setVisible(width >= screenWidth.from && width < screenWidth.to);
    };
    resizeCallback();
    window.addEventListener("resize", resizeCallback, false);
    return () => {
      window.removeEventListener("resize", resizeCallback, false);
    };
  }, [screenWidth.from, screenWidth.to]);

  useEffect(() => {
    let intersectionObserver: IntersectionObserver | undefined;
    if (loading === "lazy" && placeholderRef.current) {
      intersectionObserver = new IntersectionObserver(
        ([entry]) => {
          if (
            entry.isIntersecting || // visible in the viewport
            entry.boundingClientRect.top < 0 // above the current viewport
          ) {
            // prevent duplicate callbacks
            intersectionObserver?.unobserve(entry.target);
            setEnabled(true);
          }
        },
        { rootMargin: "600px 0px" },
      );

      intersectionObserver.observe(placeholderRef.current);
    }

    return () => {
      intersectionObserver?.disconnect();
    };
  }, [placeholderRef, loading]);

  useEffect(() => {
    if (ad.size) {
      const renderedAd = sizes.find(
        (size) =>
          ad.size?.width === size.width && ad.size?.height === size.height,
      );
      setRenderedAd(renderedAd ?? null);
    }
  }, [ad.size, sizes]);

  useEffect(() => {
    if (renderedAd && onResize) {
      onResize(renderedAd, renderedAd?.isPremium ?? false);
    }
  }, [renderedAd, onResize, sizes]);

  /**
   * Benji adstacking feature :
      1. Ads rendered with Benji adstacking feature must not have a wrapper div for display styles to work properly.
      2. Additionally, avoid using placeholders for these ads, as the feature supports either two smaller ads [250x300]
      or one large ad [250x600].
  */
  return (
    <Wrapper
      displayMode={displayMode}
      isPremium={renderedAd?.isPremium ?? false}
      locationNumber={locationNumber}
    >
      {!stackGroup && (
        <div
          className={classNames(
            className,
            displayMode === "hidden" && "hidden",
          )}
          id={containerId}
          style={getContainerStyle()}
        >
          <div
            className={displayMode === "placeholder" ? "hidden" : "flex"}
            id={adId}
            ref={elementRef}
          />
          {displayMode === "placeholder" && (
            <Placeholder size={sizes[0]} ref={placeholderRef} />
          )}
          <style type="text/css">{responsiveStyles}</style>
        </div>
      )}
      {stackGroup && (
        <>
          <div
            className={classNames(className, "flex")}
            id={adId}
            ref={elementRef}
          />
          {displayMode === "placeholder" && <span ref={placeholderRef} />}
        </>
      )}
    </Wrapper>
  );
};

interface PlaceholderProps {
  size: Size;
}

const Placeholder = forwardRef<HTMLDivElement, PlaceholderProps>(
  ({ size }, ref) => {
    const intl = useIntl();

    return (
      <div
        className="flex size-full items-center justify-center bg-marshmallow text-center leading-3 dark:bg-ramones dark:text-grey-hair"
        ref={ref}
        style={size}
      >
        {intl.formatMessage({ id: "monetization.AD_PLACEHOLDER" })}
      </div>
    );
  },
);
