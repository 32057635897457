/** Defines a range of screenWidths as `from <= screen width < to` */
export interface ScreenWidth {
  /** Inclusive (≤) */
  from: number;
  /** Exclusive (<) */
  to: number;
}

export interface Size {
  height: number;
  isPremium?: boolean;
  width: number;
}

/**
 * Constructs [from, to) media queries
 *
 * The `(min-width: ...)` and `(max-width: ...)` media queries are inclusive,
 * so the `from` value is used as-is but the `to` value is reduced by a fraction
 * of a pixel to approximate an exclusive comparison until range context queries
 * are supported. See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
 */
export const buildScreenWidthMediaQuery = ({ from, to }: ScreenWidth) =>
  [
    "screen",
    from > 0 && `(min-width: ${from}px)`,
    to !== Infinity && `(max-width: ${to - 0.05}px)`,
  ]
    .filter(Boolean)
    .join(" and ");

export const onIdle = (callback: () => void, timeout: number): (() => void) => {
  if ("requestIdleCallback" in window) {
    const callbackId = requestIdleCallback(callback, { timeout });

    return () => cancelIdleCallback(callbackId);
  }

  const timeoutId = setTimeout(callback, timeout);
  return () => clearTimeout(timeoutId);
};
