"use client";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  type FC,
  type ReactNode,
} from "react";
import {
  destroyAds,
  renderAds,
  type BenjiAdPositionConfig,
  type BenjiRenderFailedEvent,
  type BenjiRenderSucceededEvent,
} from "./benji";
import { onIdle } from "./client";
import {
  adsReducer,
  defaultAdsState,
  type AdState,
  type AdsState,
} from "./reducer";

interface AdsApi {
  destroyAd: (id: string) => void;
  enqueueAd: (config: BenjiAdPositionConfig) => void;
}

const AdsContext = createContext<AdsState & AdsApi>({
  ...defaultAdsState,
  destroyAd: (): void => {
    throw new Error("AdsContext not yet initialized");
  },
  enqueueAd: (): void => {
    throw new Error("AdsContext not yet initialized");
  },
});

/**
 * Integrates with [Benji](http://yo/benji), tracking the state of all of the
 * page's ads and batching ad renders together
 */
export const AdsProvider: FC<{
  /** Limits how long (in milliseconds) to wait before batching up ads to render */
  batchTimeout?: number;
  children: ReactNode;
}> = ({
  batchTimeout = 1000 / 60, // don't wait for more than 1 "frame" (at 60 fps)
  children,
}) => {
  const [state, dispatch] = useReducer(adsReducer, defaultAdsState);
  const cancelBatch = useRef<(() => void) | null>(null);

  // monitor benji's events
  useEffect(() => {
    if (state.ready) {
      let cancelled = false;

      window.benji.on("RENDER_FAILED", (event: BenjiRenderFailedEvent) => {
        if (cancelled) {
          return;
        }

        dispatch({ event, type: "ad:render:failed" });
      });

      window.benji.on(
        "RENDER_SUCCEEDED",
        (event: BenjiRenderSucceededEvent) => {
          if (cancelled) {
            return;
          }

          dispatch({ event, type: "ad:render:succeeded" });
        },
      );

      return () => {
        cancelled = true;
      };
    } else {
      const onBenjiReady = () => {
        dispatch({ type: "benji:ready" });
      };

      if (window.benji?.isReady) {
        onBenjiReady();
        return;
      }

      window.addEventListener("benji:ready", onBenjiReady);

      return () => {
        window.removeEventListener("benji:ready", onBenjiReady);
      };
    }
  }, [state.ready]);

  // monitor the ad queue
  useEffect(() => {
    const ids = Object.keys(state.ads).filter(
      (id) => state.ads[id].status === "queued",
    );
    if (ids.length === 0) {
      return;
    }

    cancelBatch.current?.();

    cancelBatch.current = onIdle(() => {
      dispatch({ ids, type: "ads:rendering" });
      renderAds(ids.map((id) => state.ads[id].config));

      cancelBatch.current = null;
    }, batchTimeout);

    return () => {
      cancelBatch.current?.();
      cancelBatch.current = null;
    };
  }, [batchTimeout, state.ads]);

  const destroyAd = useCallback(
    (id: string) => {
      destroyAds([id]);
      dispatch({ id, type: "ad:destroyed" }); // Benji doesn't publish any events on destruction
    },
    [dispatch],
  );

  // Initialize a ref to store ads with stackgroup temporarily before dispatching them together
  const adsByStackGroup = useRef<Record<string, BenjiAdPositionConfig[]>>({});
  const MAX_ADS_PER_STACKGROUP = 3;

  const enqueueAd = useCallback(
    (config: BenjiAdPositionConfig) => {
      // Check if the ad has a stackGroup
      if ("stackGroup" in config && config.stackGroup) {
        const group = config.stackGroup;

        // Add the ad to the queue of the respective stackGroup
        if (!adsByStackGroup.current[group]) {
          adsByStackGroup.current[group] = [];
        }

        adsByStackGroup.current[group].push(config);

        // If we have 3 ads in this stackGroup, dispatch them together
        if (adsByStackGroup.current[group].length === MAX_ADS_PER_STACKGROUP) {
          adsByStackGroup.current[group].forEach((config) => {
            dispatch({ config, type: "ad:enqueue" });
          });

          // Clear the ads for this stackGroup after dispatching
          adsByStackGroup.current[group].length = 0;
        }
      } else {
        // Dispatch normally if there's no stackGroup
        dispatch({ config, type: "ad:enqueue" });
      }
    },
    [dispatch],
  );

  return (
    <AdsContext.Provider value={{ destroyAd, enqueueAd, ...state }}>
      {children}
    </AdsContext.Provider>
  );
};

const useAds = () => useContext(AdsContext);

/**
 * Registers an ad with the AdContext, adding it to the next render batch when
 * enabled and destroying it when disabled
 */
export const useAd = ({
  config,
  enabled,
}: {
  config: BenjiAdPositionConfig;
  enabled: boolean;
}): AdState => {
  const { ads, destroyAd, enqueueAd, ready } = useAds();
  const ad = ads[config.id] ?? {
    config,
    size: null,
    status: "initial",
  };

  useEffect(() => {
    if (!enabled || !ready) {
      return;
    }

    enqueueAd(config);

    return () => {
      destroyAd(config.id);
    };
  }, [config, destroyAd, enabled, enqueueAd, ready]);

  return ad;
};
