import { type Reducer } from "react";
import {
  fromBenjiSize,
  type BenjiAdPositionConfig,
  type BenjiRenderFailedEvent,
  type BenjiRenderSucceededEvent,
} from "./benji";
import { type Size } from "./client";

export interface AdState {
  config: BenjiAdPositionConfig;
  size: Size | null;
  status: "error" | "initial" | "queued" | "rendered" | "rendering";
}

export interface AdsState {
  ads: Record<string, AdState>;
  ready: boolean;
}

export const defaultAdsState: AdsState = {
  ads: {},
  ready: false,
};

/**
 * Benji destroyed an ad
 */
interface AdDestroyedAction {
  id: string;
  type: "ad:destroyed";
}

const handleAdDestroyed: Reducer<AdsState, AdDestroyedAction> = (
  state,
  { id },
) => {
  const ads = { ...state.ads };
  delete ads[id];

  return {
    ...state,
    ads,
  };
};

/**
 * Adds an ad to the next render batch
 */
interface AdEnqueueAction {
  config: BenjiAdPositionConfig;
  type: "ad:enqueue";
}

const handleAdEnqueue: Reducer<AdsState, AdEnqueueAction> = (
  state,
  { config },
) => ({
  ...state,
  ads: {
    ...state.ads,
    [config.id]: {
      config,
      size: null,
      status: "queued",
    },
  },
});

/**
 * Benji failed to render an ad
 */
interface AdRenderFailedAction {
  event: BenjiRenderFailedEvent;
  type: "ad:render:failed";
}

const handleAdRenderFailed: Reducer<AdsState, AdRenderFailedAction> = (
  state,
  {
    event: {
      data: { id },
    },
  },
) => {
  if (!state.ads[id]) {
    return state;
  }

  return {
    ...state,
    ads: {
      ...state.ads,
      [id]: {
        config: state.ads[id].config,
        size: null,
        status: "error",
      },
    },
  };
};

/**
 * Benji successfully rendered an ad
 */
interface AdRenderSucceededAction {
  event: BenjiRenderSucceededEvent;
  type: "ad:render:succeeded";
}

const handleAdRenderSucceeded: Reducer<AdsState, AdRenderSucceededAction> = (
  state,
  {
    event: {
      data: { id, size: benjiSize },
    },
  },
) => {
  if (!state.ads[id] || !Array.isArray(benjiSize)) {
    return state;
  }

  return {
    ...state,
    ads: {
      ...state.ads,
      [id]: {
        config: state.ads[id].config,
        size: fromBenjiSize(benjiSize),
        status: "rendered",
      },
    },
  };
};

/**
 * A batch of ads was sent to Benji to render
 */
interface AdsRenderingAction {
  ids: string[];
  type: "ads:rendering";
}

const handleAdsRendering: Reducer<AdsState, AdsRenderingAction> = (
  state,
  { ids },
) => {
  const updatedAds: Record<string, AdState> = {};
  for (const id of ids) {
    if (state.ads[id]?.config) {
      updatedAds[id] = {
        config: state.ads[id].config,
        size: null,
        status: "rendering",
      };
    }
  }

  return {
    ...state,
    ads: {
      ...state.ads,
      ...updatedAds,
    },
  };
};

/**
 * Benji has finished initializing
 */
interface BenjiReadyAction {
  type: "benji:ready";
}

const handleBenjiReady: Reducer<AdsState, BenjiReadyAction> = (state) => ({
  ...state,
  ready: true,
});

export type AdsAction =
  | AdDestroyedAction
  | AdEnqueueAction
  | AdRenderFailedAction
  | AdRenderSucceededAction
  | AdsRenderingAction
  | BenjiReadyAction;

export const adsReducer: Reducer<AdsState, AdsAction> = (state, action) => {
  switch (action.type) {
    case "ad:destroyed":
      return handleAdDestroyed(state, action);

    case "ad:enqueue":
      return handleAdEnqueue(state, action);

    case "ad:render:failed":
      return handleAdRenderFailed(state, action);

    case "ad:render:succeeded":
      return handleAdRenderSucceeded(state, action);

    case "ads:rendering":
      return handleAdsRendering(state, action);

    case "benji:ready":
      return handleBenjiReady(state, action);

    default:
      return state;
  }
};
