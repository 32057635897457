"use client";

import benjiManifest from "@vzmi/benji/manifest.json";
import { useInView } from "@yahoo-news/util";
import Script from "next/script";
import { useId, type FC, useRef, useEffect } from "react";
import "./NativeAd.css";

const BENJI_URL = benjiManifest.benji.default.min;

const DEFAULT_CONFIG = {
  mode: "stream-d-rocket-v2",
  region: "index",
  targetType: "mix",
};

export const onBenjiReady = (
  callback: () => Promise<void> | void,
): (() => void) => {
  if (window?.benji?.isReady) {
    callback()?.catch((_error) => {});

    return () => {};
  }

  let ready = false;

  const cleanup = () => {
    if (ready) {
      return;
    }

    window.removeEventListener("benji:ready", onReady);
  };

  const onReady = () => {
    ready = true;
    window.removeEventListener("benji:ready", onReady);

    callback()?.catch((_error) => {});
  };

  window.addEventListener("benji:ready", onReady);

  return cleanup;
};

interface TaboolaAd {
  className?: string;
  mode?: string;
  lazyLoad?: boolean;
  placement: string;
  region?: string;
  targetType?: string;
}

const Ad: FC<TaboolaAd> = ({
  className,
  lazyLoad,
  mode,
  targetType,
  placement,
  region,
}) => {
  const id = useId();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const inView = useInView(elementRef, { threshold: 0 });

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    if (!lazyLoad) {
      onBenjiReady(() => {
        window.benji.render({
          [id]: {
            id,
            mode,
            placement,
            region,
            targetType,
          },
        });
      });
    }
  }, [id, lazyLoad, mode, placement, region, targetType]);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }
    if (lazyLoad && inView) {
      onBenjiReady(() => {
        window.benji.render({
          [id]: {
            id,
            mode,
            placement,
            region,
            targetType,
          },
        });
      });
    }
  }, [id, inView, lazyLoad, mode, placement, region, targetType]);

  return <div className={className} id={id} ref={elementRef}></div>;
};

export const NativeAd: FC<TaboolaAd> = ({
  className,
  mode = DEFAULT_CONFIG.mode,
  lazyLoad,
  placement,
  region = DEFAULT_CONFIG.region,
  targetType = DEFAULT_CONFIG.targetType,
}) => {
  return (
    <>
      <Ad
        className={className}
        lazyLoad={lazyLoad}
        mode={mode}
        placement={placement}
        region={region}
        targetType={targetType}
      />
      <Script src={BENJI_URL} />
    </>
  );
};
