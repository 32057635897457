import { type BenjiConfig } from "@vzmi/benji";
import { type Size } from "./client";

declare global {
  interface Window {
    benji: any; // TODO type?
  }
}

const BENJI_SITE_MAPPING: Record<string, string> = {
  creators: "ycreators",
  entertainment: "yent",
  finance: "yfin",
  tech: "ytech",
};

// Maps web devices to their native app equivalent
const BENJI_APP_MAPPING: Partial<Record<Device, Device>> = {
  dt: "ta",
  mw: "ma",
};

type BenjiAdPositions = BenjiConfig["positions"];
export type BenjiAdPositionConfig = BenjiAdPositions[string];

export interface BenjiRenderFailedEvent {
  data: {
    id: string;
  };
}

export interface BenjiRenderSucceededEvent {
  data: {
    id: string;
    size: [number, number] | null;
  };
}

export type BenjiPlatform = "and" | "ios";

/** [width, height] */
export type BenjiSize = [number, number];

export type Device = "dt" | "mw" | "ma" | "ta";

export const buildAdPath = ({
  device,
  location,
  placement = "main",
  platform,
  site = "${SITE}",
  stackGroup = "",
}: {
  device: Device;
  location: string;
  placement?: string;
  platform?: BenjiPlatform;
  site?: string;
  stackGroup?: string;
}) => {
  const benjiSite = BENJI_SITE_MAPPING[site] || site;
  const unitCode = stackGroup ? `as_${location}` : location;
  const platformStr = platform ? `_${platform}` : "";
  const deviceStr = platform ? BENJI_APP_MAPPING[device] || device : device;
  return `/22888152279/\${REGION}/${benjiSite}/${placement}/${deviceStr}/\${REGION}_${benjiSite}_${placement}_${deviceStr}${platformStr}_${unitCode}`;
};

export const buildBenjiAdConfig = ({
  customSizeConfig,
  device,
  id,
  kvs,
  location,
  locationNumber,
  ntsFallBack,
  placement,
  platform,
  region,
  site,
  sizes,
  stackGroup,
}: {
  customSizeConfig?: Record<string, boolean>;
  device: Device;
  id: string;
  kvs?: Record<string, string>;
  location: string;
  locationNumber: number;
  ntsFallBack?: {
    position: string;
  };
  placement: string;
  platform?: BenjiPlatform;
  region: string;
  site?: string;
  sizes: Size[];
  stackGroup?: string;
}): BenjiAdPositionConfig => ({
  customSizeConfig,
  id,
  kvs: {
    loc: locationNumber === 1 ? location : `${location}_${locationNumber}`,
    ...kvs,
  },
  ntsFallBack,
  path: buildAdPath({
    device,
    location,
    placement,
    platform,
    site,
    stackGroup,
  }),
  region,
  size: sizes.map(toBenjiSize),
  stackGroup,
});

/**
 * Destroys the given ads, by ID
 *
 * NOTE: Benji's `destroy` function has two signatures with pretty different behaviors:
 * - `destroy(input: string)` – destroys an entire region (e.g. "index") of ads, not a single ad by "<id>"
 * - `destroy(inputs: string[])` - destroys specific ads by "<id>" or by "<region>:<id>"
 */
export const destroyAds = (ids: string[]): void => {
  window.benji.destroy(ids);
};

export const fromBenjiSize = ([width, height]: BenjiSize): Size => ({
  height,
  width,
});

export const renderAds = (configs: BenjiAdPositionConfig[]) => {
  window.benji.render(
    configs.reduce(
      (existingPositions, config) => ({
        ...existingPositions,
        [config.id]: config,
      }),
      {} as BenjiAdPositions,
    ),
  );
};

export const toBenjiSize = ({ height, width }: Size): BenjiSize => [
  width,
  height,
];
